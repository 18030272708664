// Custom Theming for Angular Material
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$prism-starter-web-primary: mat.define-palette(mat.$indigo-palette);
$prism-starter-web-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$prism-starter-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$prism-starter-web-theme: mat.define-light-theme(
  $prism-starter-web-primary,
  $prism-starter-web-accent,
  $prism-starter-web-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($prism-starter-web-theme);

/* You can add global styles to this file, and also import other style files */
@import url("assets/bower_components/bootstrap/dist/css/bootstrap.min.css");
@import url("assets/bower_components/font-awesome/css/font-awesome.min.css");
@import url("assets/bower_components/Ionicons/css/ionicons.min.css");
@import url("assets/dist/css/AdminLTE.min.css");
@import url("assets/plugins/iCheck/square/blue.css");
@import url("assets/dist/css/skins/_all-skins.min.css");
@import url("assets/bower_components/jvectormap/jquery-jvectormap.css");
@import url("assets/bower_components/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css");
@import url("assets/bower_components/bootstrap-daterangepicker/daterangepicker.css");
@import url("assets/plugins/bootstrap-wysihtml5/bootstrap3-wysihtml5.min.css");
@import url("assets/bower_components/datatables.net-bs/css/dataTables.bootstrap.min.css");
@import "theme-var.scss";
@import "@angular/cdk/overlay-prebuilt.css";
@import "~bootstrap/scss/bootstrap";

.signout {
  cursor: pointer;
}

// .main-footer{
//   background: transparent !important;
//   border: none !important;
//   position: absolute;
//   bottom: 0px;
//   width: 100%;
// }
// .content {
//   min-height: 700px;
// }

body {
  font-size: 13px;
  padding: 0px !important;
}

.no-layout {
  background-image: url("./assets/Stock\ Banner\ -\ Dark.jpg") !important;
  background-size: cover;
  min-height: 100vh;
}

.login-container {
  .card {
    padding: 20px;
    border-radius: 0px;
    .card-header {
      padding: 0px;
      background-color: #fff;
      border-bottom: 0px;

      font-size: 12px;
      text-align: center;
      margin-bottom: 32px;
      color: #777;
      h4 {
        font-size: 12px;
      }
    }

    .form-group {
      margin-bottom: 15px !important;
    }

    .form-control-feedback {
      color: #777;
    }
  }
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

.point {
  cursor: pointer !important;
}

.col-md {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: none !important;
  background-color: #eee;
}

table.dataTable.no-footer {
  border-bottom: none !important;
}

div.dataTables_wrapper div.dataTables_length select {
  height: 30px !important;
  line-height: 30px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  border-color: #d2d6de !important;
  background-color: #fff !important;
}

.showCount {
  height: 30px !important;
  width: 50px !important;
  line-height: 30px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  border-color: #d2d6de !important;
  background-color: #fff !important;
}

input[type="search"] {
  border-color: #d2d6de !important;
  border: 1px solid #ccc !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  height: 30px !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  -webkit-appearance: textfield !important;
}

input[type="file"] {
  display: none;
}

table.dataTable thead .sorting_asc,
.sorting_desc {
  background-image: none !important;
}

table.dataTable thead .sorting {
  background-image: none !important;
}

tbody {
  text-align: left !important;
}

// Paginations
.paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: $medium-blue !important;
  border: solid 1px #eee !important;
  border-radius: 2px;
}

.paginate_button:hover {
  background: #f8f8f8 !important;
}

.current {
  color: #ffffff !important;
  cursor: default !important;
  background: #337ab7 !important;
  border-color: #337ab7 !important;
}

.current:hover {
  background: #256399 !important;
}

.disabled {
  cursor: default !important;
  color: #999 !important;
}

// End Paginations

.main-sidebar .user-panel {
  background-color: $dark-blue !important;
  padding-top: 8px;
  padding-bottom: 0px;
  margin-bottom: 12px;
  span {
    color: #fff;
    font-size: 28px;
  }
  .info {
    padding-top: 12px;
    padding-left: 0px;
  }

  .info-normal {
    small {
      display: none;
    }
  }

  .info-with-small-text {
    padding-top: 6px;
    padding-left: 0px;
    small {
      display: block;
      padding-bottom: 2px;
    }
  }
}

.content-wrapper {
  background-color: #eee !important;
}

.common-loader {
  position: absolute;
  top: 56%;
  left: 34%;
  z-index: 999;
}

.invalid-feedback {
  display: block;
  color: #f00;
  font-size: 10px;
}

.user-input-error {
  color: red;
  font-size: 12px;
}

.checkbox-span {
  margin-right: 15px !important;
}

.content {
  // background-color: #fff;
  padding: 0px;
}

.checkbox-roles {
}

.display {
  display: block !important;
}

#required {
  position: initial;
  top: -25px;
  right: 0px;
  // display: none;
  text-align: right;
}

#max-length {
  position: initial;
  top: -25px;
  left: 0px;
  display: none;
}

.reqired {
  color: #ccc;
  text-align: right;
}

.reqired-active {
  color: #ff0000;
  font-weight: bold;
}

.required-box {
  border-radius: 5px;
  color: #b3b3b3;
  width: 100%;
  font-weight: bold;
}
.content {
  // background-color: #fff;
  padding: 0px;
}

.hide {
  color: rgb(255, 255, 255);
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.input-reqired {
  border-color: #ff0000 !important;
}

// Select2
input[class="select2-search__field"] {
  border: none !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: #3c8dbc !important;
  border-color: #367fa9 !important;
  padding: 5px !important;
  color: #fff !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon:hover {
  background: transparent !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background-color: #54b4ec !important;
  color: #000 !important;
}

input {
  margin-bottom: 5px;
}

.alert {
  padding: 5px !important;
  margin-bottom: 15px !important;
}

// SWAL
.swal2-show {
  font-size: 14px !important;
  width: 400px !important;
}

.swal_ul {
  text-align: justify;
}

.swal_wo {
  text-align: center !important;
  padding: 0px !important;
}

.swal_li {
  margin-bottom: 15px !important;
}

// loading gif
#loading {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

// input[type="file"] {
//   display: none;
// }
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 10px 12px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  /*background: #3498db;*/
  /*color: #fff;*/
}

.custom-file-upload:hover {
  background: #e8e8e8;
}

.image-display {
  margin-bottom: 10px;
  border: solid 1px #d2d6de;
}

.input-reqired {
  border-color: #ff0000 !important;
}

.text-danger {
  color: #ff0000 !important;
}

.text-success {
  color: #28a745 !important;
}

.loading {
  display: none;
  position: fixed;
  z-index: 1;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.load-img-auth {
  top: unset !important;
  position: relative !important;
}

select.form-control:not([size]):not([multiple]) {
  height: unset !important;
}

.row {
  display: block !important;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  vertical-align: unset;
}

.navbar {
  padding-right: 15px !important;
  padding-left: 15px !important;
  padding-top: 0px;
  padding-bottom: 0px;
  display: block !important;
}

.has-feedback .form-control-feedback {
  top: 0px !important;
}

.form-group {
  margin-bottom: 0px !important;
}

i.fa {
  cursor: pointer;
}

.bgdark {
  background: rgb(226, 226, 226);
}

.margin-right-0 {
  padding-right: 0px !important;
}

.panel-heading {
  padding: 0px !important;
  border: unset !important;
}

.panel {
  border: solid 1px #868686;
}

.form-control {
  margin-bottom: 5px;
}

// section
.section-head {
  text-align: left;
  color: #fff !important;
  border-radius: unset !important;
}

.question-header {
  background: #d3d3d3;
  padding: 10px;
}

.size-20 {
  font-size: 20px !important;
}

.padding5 {
  padding: 5px 0px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.text-light {
  font-weight: initial !important;
}

.cap {
  text-transform: capitalize;
}

.text-light {
  color: #000 !important;
}

// Modal styles
modal-container {
  opacity: 1 !important;
  transition: opacity 0.15s linear !important;
}

.modal-dialog {
  top: 10%;
  height: 0px;
}

// End modal styles

.box-comments .box-comment {
  border-bottom: 5px solid #fff !important;
}

.comment-area {
  max-height: 250px !important;
  overflow-x: hidden !important;
  width: 99% !important;
}

.img-sm,
.box-comments .box-comment img,
.user-block.user-block-sm img {
  width: auto !important;
  height: 80px !important;
}

.comment-img {
  margin: 0px 2px;
}

.comment-danger {
  background: #ea9999;
  color: #aa3933;
}

.download-img {
  padding: 15px;
}

.selected-card {
  background: cornflowerblue;
}

.assigned-text {
  color: #000 !important;
}

.navbar-nav {
  display: block;
}

.main-footer {
  margin: 0;
  text-align: center;
}

.control-sidebar {
  width: 350px;
  right: -350px;
}

.control-sidebar-open {
  right: 0;
  min-height: 100%;
  background-color: rgba(34, 45, 50, 0.99);
}

// .search-panel{
//   margin-right: 350px;
// }

.skin-blue .wrapper,
.skin-blue .main-sidebar,
.skin-blue .left-side {
  background-color: $medium-blue !important;
}

.control-sidebar {
  background-color: $medium-blue !important;
}
.darker {
  background: #000000 !important;
  color: white !important;
}

.navbar-static-top-custom-style {
  background-color: #32546b !important;
}

// .admin-image {
//   background-image: url('../src/assets/Admin_Main_Menu_Image_-_cropped.jpg');
//   min-height: 485px;
//   background-position: center;
// }

// .client-image {
//   background-image: url('../src//assets/Client_Portal_-_Image_cropped.png');
//   min-height: 485px;
//   background-position: center;
// }
// .installer-image {
//   background-image: url('../src//assets/Installer_Portal_Image_-_Cropped.jpg');
//   min-height: 485px;
//   background-position: center;
// }

.admin-custom-style {
  background-color: #274e13 !important;
}
.installer-custom-style {
  background-color: #073763 !important;
}

.modal-backdrop.fade {
  opacity: 0.5 !important;
}
.modal-open .modal {
  opacity: 1 !important;
}

.common-loader {
  position: absolute;
  top: 56%;
  left: 34%;
  z-index: 999;
}

.invalid-feedback {
  display: block;
  color: #f00;
  font-size: 10px;
}

.invalid-field {
  border: 1px solid #f00 !important;
}

.invalid-field {
  border: 1px solid #f00 !important;
}
html,
body {
  height: 0% !important;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.cdk-overlay-container {
  // This makes sure the add notification dialog, or any other material dialog, is always on top and doesnt show under the navbar
  z-index: 99999 !important;

  .mat-dialog-container {
    padding: 0;
  }
}

cdk-virtual-scroll-viewport {
  height: calc(100vh - 100px);
  width: calc(100vw);
}

.cdk-virtual-scroll-content-wrapper {
  min-height: 100%;
}

.table > tbody > tr > td {
  padding: 1px;
}

.table-responsive {
  overflow-x: unset !important;
}

.dropdown-container {
  margin-top: 13px !important;
}

.wait,
.wait * {
  cursor: wait !important;
}

// This sets the first row on the month picker to be shifted to the right so the days line up properly
.ngb-dp-month .ngb-dp-week:nth-child(2) {
  justify-content: right;
}
